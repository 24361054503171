import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SendErrorComponent } from '../send-error/send-error.component';
import { SendSuccessComponent } from '../send-success/send-success.component';

@Component({
  selector: 'app-confirm-send',
  templateUrl: './confirm-send.component.html',
  styleUrls: ['./confirm-send.component.scss'],
})
export class ConfirmSendComponent implements OnInit {

  transaction: any;
  receiver: string;
  
  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() {
    if (this.transaction?.send_via === 'mobile_number') {
      this.receiver = this.transaction?.mobile_number;
    } else if (this.transaction?.send_via === 'email') {
      this.receiver = this.transaction?.email;
    } else if (this.transaction?.send_via === 'public_address') {
      this.receiver = this.transaction?.transaction?.receiver;
    }
  }

  confirm() {
    // add params to set confirming
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  async successSend() {
    const modal = await this.modalController.create({
      component: SendSuccessComponent,
      cssClass: 'send-success-modal'
    });
    return await modal.present();
  }

  async errorSend() {
    const modal = await this.modalController.create({
      component: SendErrorComponent,
      cssClass: 'send-error-modal'
    });
    return await modal.present();
  }

  dismiss(){
    this.modalController.dismiss();
  }

}
