import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CreditsService {

  constructor(
    private http: HttpClient
  ) { }

  sendCredits(item): Observable<any> {
    return this.http
      .post<any>(environment.apiUrl + '/v1/credits/send', JSON.stringify(item));
  }

  moveCredits(item): Observable<any> {
    return this.http
      .post<any>(environment.apiUrl + '/v1/credits/move', JSON.stringify(item));
  }

  convertCredits(item): Observable<any> {
    return this.http
      .post<any>(environment.apiUrl + '/v1/convert', JSON.stringify(item));
  }

  creditsLimit(): Observable<any> {
    return this.http
      .get<any>(environment.apiUrl + '/v1/user-limit-lists');
  }

}
