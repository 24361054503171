import { createReducer, on } from '@ngrx/store';
import * as fromAuthActions from '../actions/auth.actions';
import * as fromLocationActions from '../actions/location.actions';

import { AreaLocation } from 'src/app/models/location.model';

export const featureKey = 'location';

export interface State {
  other: any
}


export const initialState: State = {
  other: null
};

export const reducer = createReducer(
  initialState,
  on(fromLocationActions.getRegionSuccess, (state: State, props: any) => ({
    ...state,
    other: props.data
  })), 
  on(fromAuthActions.autoLogout, (state: State, props) => initialState)
  );
