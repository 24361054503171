/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */
import { createAction, props } from '@ngrx/store';

export enum ActionTypes {
  SEND_CREDITS_CONFIRMATION = '[Account] Send Credits Confirmation',
  SEND_CREDITS = '[Account] Send Credits',
  SEND_CREDITS_SUCCESS = '[Account] Send Credits success',
  SEND_CREDITS_FAILED = '[Account] Send Credits failed',
  MOVE_CREDITS = '[Account] Move Credits',
  MOVE_CREDITS_SUCCESS = '[Account] Move Credits Success',
  CONVERT_CREDITS_CONFIRMATION = '[Account] Convert Credits Confirmation',
  CONVERT_CREDITS = '[Account] Convert Credits',
  CONVERT_CREDITS_SUCCESS = '[Account] Convert Credits success',
  GET_CREDITS_LIMIT = '[Account] Get Credits Limit',
  GET_CREDITS_LIMIT_SUCCESS = '[Account] Get Credits Limit success',
}

export const sendCreditsConfirmaton = createAction(
  ActionTypes.SEND_CREDITS_CONFIRMATION,
  props<{ data: any }>()
);

export const sendCredits = createAction(
  ActionTypes.SEND_CREDITS,
  props<{ data: any }>()
);

export const sendCreditsSuccess = createAction(
  ActionTypes.SEND_CREDITS_SUCCESS,
  props<{ data: any }>()
);

export const sendCreditsFailed = createAction(
  ActionTypes.SEND_CREDITS_FAILED,
  props<{ data: any }>()
);
export const moveCredits = createAction(
  ActionTypes.MOVE_CREDITS,
  props<{ data: any }>()
);

export const moveCreditsSuccess = createAction(
  ActionTypes.MOVE_CREDITS_SUCCESS,
  props<{ data: any }>()
);

export const convertCreditsConfirmaton = createAction(
  ActionTypes.CONVERT_CREDITS_CONFIRMATION,
  props<{ data: any }>()
);

export const convertCredits = createAction(
  ActionTypes.CONVERT_CREDITS,
  props<{ data: any }>()
);

export const convertCreditsSuccess = createAction(
  ActionTypes.CONVERT_CREDITS_SUCCESS,
  props<{ data: any }>()
);

export const getCreditsLimit = createAction(
  ActionTypes.GET_CREDITS_LIMIT
);

export const getCreditsLimitSuccess = createAction(
  ActionTypes.GET_CREDITS_LIMIT_SUCCESS,
  props<{ data: any }>()
);