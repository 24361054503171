import { Injectable, ViewChildren, QueryList } from '@angular/core';
import {
  IonRouterOutlet,
  ActionSheetController,
  PopoverController,
  ModalController,
  MenuController,
} from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class AutoCloseOverlaysService {

  @ViewChildren(IonRouterOutlet) routerOutlets: QueryList<IonRouterOutlet>;
  lastTimeBackPress = 0;
  timePeriodToExit = 2000;

  constructor(
    private actionSheetCtrl: ActionSheetController,
    private popoverCtrl: PopoverController,
    private modalCtrl: ModalController,
    private menu: MenuController
  ) {}

  async trigger() {
    try {
      const element = await this.actionSheetCtrl.getTop();
      if (element && element != undefined) {
        element.dismiss();
        return true;
      }
    } catch (error) {}

    // close popover
    try {
      const element = await this.popoverCtrl.getTop();
      if (element && element != undefined) {
        element.dismiss();
        return true;
      }
    } catch (error) {}

    // close modal
    try {
      const element = await this.modalCtrl.getTop();
      if (element && element != undefined) {
        element.dismiss();
        return true;
      }
    } catch (error) {
      console.log(error);
    }

    // close side menua
    try {
      const element = await this.menu.getOpen();
      if (element && element != undefined) {
        this.menu.close();
        return true;
      }
    } catch (error) {}
    
    return false;
  }
}
