import { Injectable, NgZone } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, exhaustMap, catchError, tap, withLatestFrom, mergeMap, filter, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as fromCreditsActions from '../actions/credits.actions';
import * as fromWalletActions from '../actions/wallet.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { ErrorService } from '../../services/error.service';
import { setLoadingSpinner } from '../actions/shared.actions';
import { AlertService } from '../../services/alert.service';
import { RouterNavigatedAction, ROUTER_NAVIGATION } from '@ngrx/router-store';
import { APIResponse } from 'src/app/models/api-response.model';
import { WalletService } from 'src/app/services/wallet.service';
import { ModalController, NavController } from '@ionic/angular';
import { DashboardService } from 'src/app/services/dashboard.service';
import { WithdrawSubmitComponent } from 'src/app/shared/modals/withdraw-submit/withdraw-submit.component';
import { WithdrawFailedComponent } from 'src/app/shared/modals/withdraw-failed/withdraw-failed.component';
import { ErrorComponent } from 'src/app/shared/modals/error/error.component';

@Injectable()
export class WalletEffects {

  // getAllWallet$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(fromWalletActions.getAllWallet),
  //     mergeMap(() => {
  //       return this.walletService.allWallets().pipe(
  //         map((response: APIResponse) => {
  //           console.log('getAllWallet$', response);
  //           return fromWalletActions.getAllWalletSuccess({ data: response.details});
  //         })
  //       );
  //     })
  //   )
  // );

  // allWallet$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(ROUTER_NAVIGATION),
  //     filter((r : RouterNavigatedAction) => {
  //       return r.payload.routerState.url.startsWith('/home/bottom-tabs/dashboard') ||
  //       r.payload.routerState.url.startsWith('/home/bottom-tabs/wallet') ||
  //       r.payload.routerState.url.startsWith('/home/bottom-tabs/crypto-profile') ||
  //       r.payload.routerState.url.startsWith('/home/send-credit') ||
  //       r.payload.routerState.url.startsWith('/home/move-credit')
  //     }),
  //     mergeMap(() => {
  //       return this.walletService.allWallets().pipe(
  //         map((response: APIResponse) => {
  //           console.log('allWallet$', response);
  //           return fromWalletActions.getAllWalletSuccess({ data: response.details});
  //         })
  //       );
  //     })
  //   );
  // });

  // addSubWallet$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(fromWalletActions.addSubWallet),
  //     switchMap((action) =>
  //       this.walletService.createWallet(action.data).pipe(
  //         map((response) => {
  //           this.store.dispatch(setLoadingSpinner({ status: false }));
  //           this.store.dispatch(fromWalletActions.getAllWallet());
  //           this.store.dispatch(fromWalletActions.dashboardBalance());
  //           this.modalController.dismiss();
  //           return fromWalletActions.addSubWalletSuccess({ data: response });
  //         }),
  //         catchError((response) => {
  //           console.error('updateSubWallet$', response);
  //           const error = {
  //             status: response.status,
  //             error: this.errorService.getErrorMessage(response)
  //           };
  //           this.alertService.showErrorMessage(error?.error);
  //           return of(setLoadingSpinner({ status: false }));
  //         })
  //       )
  //     )
  //   )
  // );



  // updateSubWallet$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(fromWalletActions.updateSubWallet),
  //     exhaustMap((action) =>
  //       this.walletService.updateWallet(action.id, action.data).pipe(
  //         map((response) => {
  //           this.store.dispatch(setLoadingSpinner({ status: false }));
  //           this.store.dispatch(fromWalletActions.getAllWallet());
  //           this.store.dispatch(fromWalletActions.dashboardBalance());
  //           this.modalController.dismiss();
  //           return fromWalletActions.updateSubWalletSuccess({ data: response });
  //         }),
  //         catchError((response) => {
  //           console.error('updateSubWallet$', response);
  //           const error = {
  //             status: response.status,
  //             error: this.errorService.getErrorMessage(response)
  //           };
  //           this.alertService.showErrorMessage(error?.error);
  //           return of(setLoadingSpinner({ status: false }));
  //         })
  //       )
  //     )
  //   )
  // );

  // deleteSubWallet$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(fromWalletActions.deleteSubWallet),
  //     exhaustMap((action) =>
  //       this.walletService.deleteWallet(action.id).pipe(
  //         map((response) => {
  //           this.store.dispatch(setLoadingSpinner({ status: false }));
  //           this.store.dispatch(fromWalletActions.getAllWallet());
  //           this.modalController.dismiss({
  //             'dismissed': true
  //           });
  //           return fromWalletActions.deleteSubWalletSuccess({ data: response });
  //         }),
  //         catchError((response) => {
  //           console.error('deleteSubWallet$', response);
  //           const error = {
  //             status: response.status,
  //             error: this.errorService.getErrorMessage(response)
  //           };
  //           this.alertService.showErrorMessage(error?.error);
  //           return of(setLoadingSpinner({ status: false }));
  //         })
  //       )
  //     )
  //   )
  // );

  // dashboardBalance$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(fromWalletActions.dashboardBalance),
  //     mergeMap(() => { 
  //       return this.dashboardService.balance().pipe(
  //         map((response: APIResponse) => {
  //           console.log('dashboardBalance$', response);
  //           return fromWalletActions.dashboardBalanceSuccess({ data: response.details});
  //         })
  //       );
  //     })
  //   )
  // );

  // withdrawWallet$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(fromWalletActions.withdrawWallet),
  //     exhaustMap((action) =>
  //       this.walletService.withdraw(action.data).pipe(
  //         map((response) => {
  //           this.store.dispatch(setLoadingSpinner({ status: false }));
  //           this.store.dispatch(fromWalletActions.getAllWallet());
  //           return fromWalletActions.withdrawWalletSuccess({ data: response });
  //         }),
  //         catchError((response) => {
  //           console.error('withdrawWallet$', response);
  //           this.store.dispatch(setLoadingSpinner({ status: false }));
  //           const error = {
  //             status: response.status,
  //             error: this.errorService.getErrorMessage(response)
  //           };
  //           return of(fromWalletActions.withdrawWalletFailed({ data: error }));
  //         })
  //       )
  //     )
  //   )
  // );

  // withdrawWalletSuccess$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(fromWalletActions.withdrawWalletSuccess),
  //       tap( async (action) => {
  //         console.log('withdrawWalletSuccess$', action.data);
  //         this.ngZone.run(() => {
  //           this.navCtrl.back();
  //         });
  //         const modal = await this.modalController.create({
  //           component: WithdrawSubmitComponent,
  //           cssClass: 'withdraw-request-modal'
  //         });
  //         return modal.present();
  //       })
  //     ),
  //   { dispatch: false }
  // );

  // withdrawWalletFailed$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(fromWalletActions.withdrawWalletFailed),
  //       tap( async (action) => {
  //         console.log('withdrawWalletFailed$', action.data);
  //         const modal = await this.modalController.create({
  //           component: ErrorComponent,   
  //           cssClass: 'login-error-modal',
  //           componentProps: { error: action.data } 
  //         });
  //         return await modal.present();
  //       })
  //     ),
  //   { dispatch: false }
  // );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private errorService: ErrorService,
    private alertService: AlertService,
    private walletService: WalletService,
    private dashboardService: DashboardService,
    private navCtrl: NavController,
    private modalController: ModalController,
    private ngZone: NgZone
  ) {}
}
