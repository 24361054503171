import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { OfflineStorageService } from '../services/offline-storage.service';

export const INTRO_KEY = 'intro-seen';

@Injectable({
  providedIn: 'root',
})
export class IntroGuard implements CanLoad {
  constructor(
    private navCtrl: NavController,
    private offlineStorageService: OfflineStorageService
  ) {}

  canLoad(): Promise<boolean> {
    return this.offlineStorageService.getObject(INTRO_KEY).then(res => {
      if (res) {
        this.navCtrl.navigateRoot('home/dashboard');
        return false;
      } else {
        return true;
      }
    });
  }
}
