import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-send-error',
  templateUrl: './send-error.component.html',
  styleUrls: ['./send-error.component.scss'],
})
export class SendErrorComponent implements OnInit {

  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() {}
 

  dismiss(){
    this.modalController.dismiss();
  }

}
