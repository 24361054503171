import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserProfile } from '../models/user-profile.model';

@Injectable({
  providedIn: 'root',
})
export class WalletService {

  constructor(
    private http: HttpClient
  ) { }

  allWallets(): Observable<any> {
    return this.http
      .get<UserProfile>(environment.apiUrl + '/v1/wallets');
  }

  getWalletById(id = 0): Observable<any> {
    return this.http
      .get<any>(environment.apiUrl + `/v1/wallets/${id}`);
  }

  createWallet(item): Observable<any> {
    return this.http
      .post<any>(environment.apiUrl + '/v1/wallet/create', JSON.stringify(item));
  }

  updateWallet(id = 0, item): Observable<any> {
    return this.http
      .put<any>(environment.apiUrl + `/v1/wallet/update/${id}`, JSON.stringify(item));
  }

  deleteWallet(id = 0): Observable<any> {
    return this.http
      .delete<any>(environment.apiUrl + `/v1/wallet/delete/${id}`);
  }

  withdraw(item): Observable<any> {
    return this.http
      .post<any>(environment.apiUrl + '/v1/withdraw/request', JSON.stringify(item));
  }

}
