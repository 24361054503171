/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */
import { createAction, props } from '@ngrx/store';

export enum ActionTypes {
  GET_ALL_WALLET = '[Account] Get all wallet',
  GET_ALL_WALLET_SUCCESS = '[Account] Get all wallet success',
  ADD_SUB_WALLET = '[Account] Add sub wallet',
  ADD_SUB_WALLET_SUCCESS = '[Account] Add sub wallet Success',
  UPDATE_SUB_WALLET = '[Account] Update sub wallet',
  UPDATE_SUB_WALLET_SUCCESS = '[Account] Update sub wallet Success',
  DELETE_SUB_WALLET = '[Account] Delete sub wallet',
  DELETE_SUB_WALLET_SUCCESS = '[Account] Delete sub wallet Success',
  DASHBOARD_BALANCE = '[Account] Get dashboard balance',
  DASHBOARD_BALANCE_SUCCESS = '[Account] Get dashboard balance success',
  WITHDRAW_WALLET = '[Account] Withdraw wallet',
  WITHDRAW_WALLET_SUCCESS = '[Account] Withdraw wallet Success',
  WITHDRAW_WALLET_FAILED = '[Account] Withdraw wallet Failed',
}

export const getAllWallet = createAction(
  ActionTypes.GET_ALL_WALLET
);

export const getAllWalletSuccess = createAction(
  ActionTypes.GET_ALL_WALLET_SUCCESS,
  props<{ data: any }>()
);

export const addSubWallet = createAction(
  ActionTypes.ADD_SUB_WALLET,
  props<{ data: any }>()
);

export const addSubWalletSuccess = createAction(
  ActionTypes.ADD_SUB_WALLET_SUCCESS,
  props<{ data: any }>()
);

export const updateSubWallet = createAction(
  ActionTypes.UPDATE_SUB_WALLET,
  props<{ id: number, data: any }>()
);

export const updateSubWalletSuccess = createAction(
  ActionTypes.UPDATE_SUB_WALLET_SUCCESS,
  props<{ data: any }>()
);

export const deleteSubWallet = createAction(
  ActionTypes.DELETE_SUB_WALLET,
  props<{ id: number }>()
);

export const deleteSubWalletSuccess = createAction(
  ActionTypes.DELETE_SUB_WALLET_SUCCESS,
  props<{ data: any }>()
);

export const dashboardBalance = createAction(
  ActionTypes.DASHBOARD_BALANCE  
);

export const dashboardBalanceSuccess = createAction(
  ActionTypes.DASHBOARD_BALANCE_SUCCESS,
  props<{ data: any }>()
);

export const withdrawWallet = createAction(
  ActionTypes.WITHDRAW_WALLET,
  props<{ data: any }>()
);

export const withdrawWalletSuccess = createAction(
  ActionTypes.WITHDRAW_WALLET_SUCCESS,
  props<{ data: any }>()
);

export const withdrawWalletFailed = createAction(
  ActionTypes.WITHDRAW_WALLET_FAILED,
  props<{ data: any }>()
);