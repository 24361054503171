import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  map,
  catchError,
  tap,
  mergeMap,
  withLatestFrom,
  exhaustMap,
} from 'rxjs/operators';
import { of } from 'rxjs';
import * as fromAuthActions from '../actions/auth.actions';
import { NavigationExtras, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { getCurrentRoute } from '../selectors/router.selector';
import { AuthService } from 'src/app/services/auth.service';
import { AlertService } from 'src/app/services/alert.service';
import { setLoadingSpinner } from '../actions/shared.actions';
import { ErrorService } from 'src/app/services/error.service';
import { UserSession } from 'src/app/models/user-session.model';
import { getUserProfile } from '../actions/account.actions';
import { ModalController, NavController } from '@ionic/angular';
import { ErrorComponent } from 'src/app/shared/modals/error/error.component';
import { dashboardBalance, getAllWallet } from '../actions/wallet.actions';
import { getAllRates } from '../actions/rates.actions';
import { SuccessComponent } from 'src/app/shared/modals/success/success.component';
import { UpgradeComponent } from 'src/app/shared/modals/upgrade/upgrade.component';
import { OfflineStorageService } from 'src/app/services/offline-storage.service';

@Injectable()
export class AuthEffects {

  logIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthActions.logIn),
      exhaustMap((action) =>
        this.authService.logIn(action).pipe(
          map((response: any) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            const user = this.authService.formatUser(response.details);
            console.log('USER: ', user);
            this.authService.setUserSession(user);
            return fromAuthActions.logInSuccess({ data: user, redirect: true });
          }),
          catchError((response) => {
            console.error('logIn$', response);
            this.store.dispatch(setLoadingSpinner({ status: false }));
            const error = {
              status: response.status,
              error: this.errorService.getErrorMessage(response)
            };
            return of(fromAuthActions.loginFailed({ data: error }));
          })
        )
      )
    )
  );

  loginRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAuthActions.logInSuccess),
        tap(async (action) => {
          console.log('loginRedirect$', action.data);
          if (action.redirect) {
            // this.store.dispatch(getUserProfile());
            // this.store.dispatch(dashboardBalance());
            // this.store.dispatch(getAllWallet());
            // this.store.dispatch(getAllRates());
            // const modal = await this.modalController.create({
            //   component: UpgradeComponent,   
            //   cssClass: 'upgrade-modal'                     
            // });   
            // await modal.present();  
            // this.storage.setObject('')
            this.navCtrl.navigateRoot('home/bottom-tabs/dashboard');
          }
        })
      ),
    { dispatch: false }
  );

  

  loginFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAuthActions.loginFailed),
        tap(async (action) => {
          console.log('loginFailed$', action.data);   
          window.dispatchEvent(new CustomEvent('auth:login', { detail: action.data?.error}));   
        })
      ),
    { dispatch: false }
  );

  autoLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthActions.autoLogin),
      mergeMap(async () => {
        const user = await this.authService.getUserSession();
        return fromAuthActions.logInSuccess({ data: user, redirect: user ? true : false });
      })
    )
  );

  signUp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthActions.signUp),
      exhaustMap((action) =>
        this.authService.signUp(action.data).pipe(
          map((response) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            const tempUserSession = new UserSession(
              response?.details?.access_token,
              new Date,
              {
                email: action.data?.email,
                mobile_number: action.data?.mobile_number,
                message: response?.message
              }
            );
            return fromAuthActions.signUpSuccess({ tempUserSession });
          }),
          catchError((response) => {
            console.error('signUp$', response);
            this.store.dispatch(setLoadingSpinner({ status: false }));
            const error = {
              status: response.status,
              error: this.errorService.getErrorMessage(response)
            };
            return of(fromAuthActions.signUpFailed({ data: error }));
          })
        )
      )
    )
  );

  signUpSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAuthActions.signUpSuccess),
        tap(async (action) => {
          console.log('signUpSuccess$ tempUserSession', action.tempUserSession);
          this.navCtrl.navigateForward('/auth/register-verify', { replaceUrl: true });
        })
      ),
    { dispatch: false }
  );

  signUpFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAuthActions.signUpFailed),
        tap(async (action) => {
          console.log('signUpFailed$', action.data);          
          const modal = await this.modalController.create({
            component: ErrorComponent,   
            cssClass: 'login-error-modal',
            componentProps: { error: action.data } 
          });
          await modal.present();
              
        })
      ),
    { dispatch: false }
  );

  verifySignUp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthActions.verifySignUp),
      exhaustMap((action) =>
        this.authService.verify_otp(action.data).pipe(
          map((response: any) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            // this.alertService.showInfoMessage(response?.message);
            return fromAuthActions.verifySignUpSuccess({ data: response });
          }),
          catchError((response) => {
            console.error('verifySignUp$', response);
            this.store.dispatch(setLoadingSpinner({ status: false }));
            const error = {
              status: response.status,
              error: this.errorService.getErrorMessage(response)
            };
            return of(fromAuthActions.verifySignUpFailed({ data: error }));
          })
        )
      )
    )
  );

  verifySignUpSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAuthActions.verifySignUpSuccess),
        tap(async (action) => {
          console.log('verifySignUpSuccess$', action.data);
          const modal = await this.modalController.create({
            component: SuccessComponent,   
            cssClass: 'login-error-modal',
            componentProps: { data: action.data, page: 'register' }   
          });
          await modal.present();
          this.navCtrl.navigateRoot('auth/login');
        })
      ),
    { dispatch: false }
  );

  verifySignUpFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAuthActions.verifySignUpFailed),
        tap(async(action) => {
          console.log('verifySignUpFailed$', action.data);          
          const modal = await this.modalController.create({
            component: ErrorComponent,   
            cssClass: 'login-error-modal',
            componentProps: { error: action.data } 
          });
          await modal.present();
        })
      ),
    { dispatch: false }
  );

  sendOtp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthActions.sendOtp),
      exhaustMap((action) =>
        this.authService.send_otp(action.data).pipe(
          map((response: any) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return fromAuthActions.sendOtpSuccess({ data: response });
          }),
          catchError((response) => {
            console.error('sendOtp$', response);
            this.store.dispatch(setLoadingSpinner({ status: false }));
            const error = {
              status: response.status,
              error: this.errorService.getErrorMessage(response)
            };
            this.alertService.showErrorMessage(error?.error);
            return of(fromAuthActions.sendOtpFailed({ data: response }));
          })
        )
      )
    )
  );

  forgot$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthActions.forgotPassword),
      withLatestFrom(this.store.select(getCurrentRoute), (action, router) => {
        if (router.url.startsWith('/auth/verification')) {
          const username = router.params['username'];
          return { ...action, username };
        } else {
          return action;
        }
      }),
      exhaustMap((action) =>
        this.authService.forgotPassword(action).pipe(
          map((response) => {
            // this.alertService.showInfoMessage(response.message);
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return fromAuthActions.forgotSuccess({
              data: {
                username: action.username,
              },
            });
          }),
          catchError((response) => {
            console.error('forgot$', response);
            this.store.dispatch(setLoadingSpinner({ status: false }));
            const error = {
              status: response.status,
              error: this.errorService.getErrorMessage(response)
            };
            return of(fromAuthActions.forgotFailed({ data: error }));
          })
        )
      )
    )
  );

  forgotSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAuthActions.forgotSuccess),
        tap((action) => {
          console.log('forgotSuccess$', action.data);
          this.navCtrl.navigateForward(`auth/verification/${action.data.username}`, {
            replaceUrl: true,
          });
        })
      ),
    { dispatch: false }
  );

  forgotFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAuthActions.forgotFailed),
        tap(async(action) => {
          console.log('forgotFailed$', action.data);          
          const modal = await this.modalController.create({
            component: ErrorComponent,   
            cssClass: 'login-error-modal',
            componentProps: { error: action.data } 
          });
          await modal.present();
        })
      ),
    { dispatch: false }
  );

  verifyForgotPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthActions.verifyForgotPassword),
      withLatestFrom(this.store.select(getCurrentRoute), (action, router) => {
        const username = router.params['username'];
        return {
          data: { ...action.data, username },
        };
      }),
      exhaustMap((action) =>
        this.authService.forgotPasswordVerify(action.data).pipe(
          map((response: any) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            const tempUserSession = new UserSession(
              response?.details?.access_token,
              new Date,
              {
                username: action.data?.username
              }
            );
            return fromAuthActions.verifyForgotPasswordSuccess({ tempUserSession });
          }),
          catchError((response) => {
            console.error('verifySignUp$', response);
            this.store.dispatch(setLoadingSpinner({ status: false }));
            const error = {
              status: response.status,
              error: this.errorService.getErrorMessage(response)
            };
            return of(fromAuthActions.verifyForgotPasswordFailed({ data: error }));
          })
        )
      )
    )
  );

  verifyForgotPasswordSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAuthActions.verifyForgotPasswordSuccess),
        tap(async (action) => {
          console.log('verifyForgotPasswordSuccess$', action.tempUserSession);
          const userData = action.tempUserSession?.userData;
          this.navCtrl.navigateForward(`auth/confirm-password/${userData?.username}`, {
            replaceUrl: true,
          });
        })
      ),
    { dispatch: false }
  );

  verifyForgotPasswordFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAuthActions.verifyForgotPasswordFailed),
        tap(async (action) => {
          const modal = await this.modalController.create({
            component: ErrorComponent,   
            cssClass: 'login-error-modal',
            componentProps: { error: action.data } 
          });
          await modal.present();
        })
      ),
    { dispatch: false }
  );

  confirmPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuthActions.confirmPassword),
      withLatestFrom(this.store.select(getCurrentRoute), (action, router) => {
        const username = router.params['username'];
        return {
          data: { ...action.data, username },
        };
      }),
      exhaustMap((action) =>
        this.authService.forgotPasswordConfirm(action.data).pipe(
          map((response) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return fromAuthActions.confirmSuccess({ data: response });
          }),
          catchError((response) => {
            console.error('confirmPassword$', response);
            this.store.dispatch(setLoadingSpinner({ status: false }));
            const error = {
              status: response.status,
              error: this.errorService.getErrorMessage(response)
            };
            return of(fromAuthActions.confirmFailed({ data: error }));
          })
        )
      )
    )
  );

  confirmSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAuthActions.confirmSuccess),
        tap(async(action) => {
          console.log('confirmSuccess$', action.data);
          // this.alertService.showInfoMessage(action.data.message);
          const modal = await this.modalController.create({
            component: SuccessComponent,   
            cssClass: 'login-error-modal',
            componentProps: { data: action.data, page: 'confirm-forgot-password' }   
          });
          await modal.present();
          this.navCtrl.navigateRoot('auth/login');
        })
      ),
    { dispatch: false }
  );

  confirmFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAuthActions.confirmFailed),
        tap(async (action) => {
          const modal = await this.modalController.create({
            component: ErrorComponent,   
            cssClass: 'login-error-modal',
            componentProps: { error: action.data } 
          });
          await modal.present();
        })
      ),
    { dispatch: false }
  );

  logOut$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAuthActions.autoLogout),
        tap(() => {
          this.authService.logout();
          this.navCtrl.navigateRoot('/auth/login');
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private navCtrl: NavController,
    private store: Store<AppState>,
    private alertService: AlertService,
    private errorService: ErrorService,
    private modalController: ModalController,
    private storage: OfflineStorageService
  ) {}
}
