import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { AppState } from '../store/app.state';
import { autoLogout } from '../store/actions/auth.actions';
import { UserSession } from '../models/user-session.model';
import { OfflineStorageService } from './offline-storage.service';

const USER_SESSION = 'userSession';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  // Init with null to filter out the first value in a guard!
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  timeoutInterval: any;

  constructor(
    private http: HttpClient,
    private store: Store<AppState>,
    private offlineStorageService: OfflineStorageService
  ) { }

  send_otp(item): Observable<any> {
    return this.http
      .post<any>(environment.apiUrl + '/v1/resend-otp', JSON.stringify(item));
  }

  verify_otp(item): Observable<any> {
    return this.http
      .post<any>(environment.apiUrl + '/v1/verify-otp', JSON.stringify(item));
  }

  logIn(item): Observable<any> {
    return this.http
      .post<any>(environment.apiUrl + '/api/auth/login', JSON.stringify(item));
  }

  signUp(item): Observable<any> {
    return this.http
      .post<any>(environment.apiUrl + '/v1/register', JSON.stringify(item));
  }

  forgotPassword(item): Observable<any> {
    return this.http
      .post<any>(environment.apiUrl + '/v1/forgot-password/send-otp', JSON.stringify(item));
  }

  forgotPasswordVerify(item): Observable<any> {
    return this.http
      .post<any>(environment.apiUrl + '/v1/forgot-password/verify', JSON.stringify(item));
  }

  forgotPasswordConfirm(item): Observable<any> {
    return this.http
      .post<any>(environment.apiUrl + '/v1/forgot-password/set-new-password', JSON.stringify(item));
  }

  formatUser(data: any) {
    const expirationDate = new Date(
      new Date().getTime() + +data.expiresIn * 1000
    );
    // const expirationDate = new Date();
    // expirationDate.setHours( expirationDate.getHours() + 24 );

    const userSession = new UserSession(
      data.token, // accessToken
      expirationDate, // expirationDate,
      data.user // userData
    );
    return userSession;
  }

  async setUserSession(userSession: UserSession) {
    this.offlineStorageService.setObject(USER_SESSION, userSession);
    this.runTimeoutInterval(userSession);
  }

  runTimeoutInterval(userSession: UserSession) {
    const todayDate = new Date().getTime();
    const expirationDate = userSession.expiration_date.getTime();
    const timeInterval = expirationDate - todayDate;
    console.log({timeInterval});
    this.timeoutInterval = setTimeout(() => {
      window.dispatchEvent(new CustomEvent('auth:logout', { detail: true}));
    }, timeInterval);
  }

  async getUserSession() {
    const userSessionLocal: any = await this.offlineStorageService.getObject(USER_SESSION);
    if (userSessionLocal) {
      const expirationDate = new Date(userSessionLocal.expirationDate);
      const userSession = new UserSession(
        userSessionLocal.accessToken,
        // userSessionLocal.refreshToken,
        expirationDate,
        userSessionLocal.userData
      );
      this.runTimeoutInterval(userSession);
      return userSession;
    }
    return null;
  }


  async logout() {
    await this.offlineStorageService.removeItem(USER_SESSION);
    if (this.timeoutInterval) {
      clearTimeout(this.timeoutInterval);
      this.timeoutInterval = null;
    }
  }
}
