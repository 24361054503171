import { AuthEffects } from './auth.effects';
import { SharedEffects } from './shared.effects';
import { AccountEffects } from './account.effects';
import { WalletEffects } from './wallet.effects';
import { CreditsEffects } from './credits.effects';
import { LocationEffect } from './location.effects';
import { RatesEffects } from './rates.effects';

export const effects: any[] = [
    AuthEffects, 
    SharedEffects, 
    AccountEffects, 
    WalletEffects,
    CreditsEffects,
    LocationEffect,
    RatesEffects
];
