import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-convert-success',
  templateUrl: './convert-success.component.html',
  styleUrls: ['./convert-success.component.scss'],
})
export class ConvertSuccessCompomnent implements OnInit {

  transaction: any;
  
  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() {}

  dismiss(){
    this.modalController.dismiss();
  }

}
