import { Injectable, NgZone } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, exhaustMap, catchError, tap, withLatestFrom, mergeMap, filter, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as fromAccountActions from '../actions/account.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { ErrorService } from '../../services/error.service';
import { setLoadingSpinner } from '../actions/shared.actions';
import { AlertService } from '../../services/alert.service';
import { AccountService } from 'src/app/services/account.service';
import { ModalController, NavController } from '@ionic/angular';
import { RouterNavigatedAction, ROUTER_NAVIGATION } from '@ngrx/router-store';
import { APIResponse } from 'src/app/models/api-response.model';
import { UpgradeComponent } from 'src/app/shared/modals/upgrade/upgrade.component';
import { getUserProfile, getUserProfileSuccess } from '../actions/account.actions';
import { Router } from '@angular/router';
import { ErrorComponent } from 'src/app/shared/modals/error/error.component';
import { AuthService } from 'src/app/services/auth.service';
import { CongratulationsComponent } from 'src/app/shared/modals/congratulations/congratulations.component';
import { getKYCInfo } from '../selectors/account.selectors';
import { SharedService } from 'src/app/services/shared.service';

@Injectable()
export class AccountEffects {

  getUserProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAccountActions.getUserProfile),
      mergeMap(() => {
        return this.accountService.userProfile().pipe(
          map((response: APIResponse) => {           
            console.log('userProfile$', response);
            return fromAccountActions.updateUserProfileSuccess({ data: response.details});
          })
        );
      })
    )
  );

  userProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      filter((r : RouterNavigatedAction) => {
        return r.payload.routerState.url.startsWith('/home/bottom-tabs/profile')
        ||  r.payload.routerState.url.startsWith('/home/bottom-tabs/dashboard')
      }),
      mergeMap(() => {
        return this.accountService.userProfile().pipe(
          map((response: APIResponse) => {
            console.log('userProfile$', response);
            return fromAccountActions.updateUserProfileSuccess({ data: response});
          })
        );
      })
    );
  });

  getOnlineAttendance$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      filter((r: RouterNavigatedAction) => {
        return r.payload.routerState.url.startsWith('/home/bottom-tabs/attendance-history');
      }),
      switchMap((action) => {
        return this.accountService.attendances().pipe(
          map((response) => {
            console.log('getOnlineAttendance$', response);
            return fromAccountActions.setOnlineAttendance({ data: response.details });
          })
        );
      })
    );
  });

  timeInOut$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAccountActions.timeInOut),
      exhaustMap((action) =>
        this.accountService.timeInOut(action.data).pipe(
          map((response: any) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            this.alertService.showInfoMessage(response?.message);
            return fromAccountActions.attendanceSuccess({ data: response?.details });
          }),
          catchError((response) => {
            console.error('timeInOut$', response);
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return of(fromAccountActions.attendanceFailed({ data: action.data, transRequest: 'time_in', error: response }));
          })
        )
      )
    )
  );

  attendanceSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAccountActions.attendanceSuccess),
        tap((action) => {
          console.log('attendanceSuccess$', action.data);
          this.navCtrl.navigateRoot('home/bottom-tabs/attendance-history', { replaceUrl: true });
        })
      ),
    { dispatch: false }
  );

  attendanceFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAccountActions.attendanceFailed),
        tap(async (action) => {
          console.log('attendanceFailed$', action.data);
          const errorMessage = this.errorService.getErrorMessage(action.error);
          this.alertService.showErrorMessage(errorMessage);
        })
      ),
    { dispatch: false }
  );

  changePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAccountActions.changePassword),
      exhaustMap((action) =>
        this.accountService.changePassword(action.data).pipe(
          map((response) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            return fromAccountActions.changePasswordSuccess({ data: response });
          }),
          catchError((response) => {
            console.error('changePassword$', response);
            this.store.dispatch(setLoadingSpinner({ status: false }));
            const error = {
              status: response.status,
              error: this.errorService.getErrorMessage(response)
            };
            return of(fromAccountActions.changePasswordFailed({ data: error }));
          })
        )
      )
    )
  );

  changePasswordSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAccountActions.changePasswordSuccess),
        tap((action) => {
          console.log('changePasswordSuccess$', action.data);
          this.alertService.showInfoMessage(action.data.message);
          this.ngZone.run(() => {
            this.navCtrl.back();
          });
        })
      ),
    { dispatch: false }
  );

  changePasswordFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAccountActions.changePasswordFailed),
        tap((action) => {
          console.log('changePasswordFailed$', action.data);
          this.store.dispatch(setLoadingSpinner({ status: false }));     
          this.alertService.showInfoMessage(action.data.error);       
          // window.dispatchEvent(new CustomEvent('account:changePassword', { detail: action.data?.error}));      
        })
      ),
    { dispatch: false }
  );

  // upgradeAccount$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(fromAccountActions.upgradeAccount),
  //     exhaustMap((action) =>
  //       this.accountService.upgradeAccount(action.data).pipe(
  //         map((response) => {
  //           this.store.dispatch(setLoadingSpinner({ status: false }));            
  //           return fromAccountActions.upgradeAccountSuccess( response );
  //         }),
  //         catchError((response) => {
  //           console.error('upgradeAccount$', response);
  //           this.store.dispatch(setLoadingSpinner({ status: false }));
  //           const error = {
  //             status: response.status,
  //             error: this.errorService.getErrorMessage(response)
  //           };
  //           return of(fromAccountActions.upgradeAccountFailed({ data: error }));
  //         })
  //       )
  //     )
  //   )
  // );

  // upgradeAccountSuccess$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(fromAccountActions.upgradeAccountSuccess),
  //       tap(async (action) => {
  //         console.log('upgradeAccountSucces$ tempUserSession', action);
  //         this.store.dispatch(getUserProfile());
  //         this.navCtrl.navigateRoot('/home/dashboard');
  //         const modal = await this.modalController.create({
  //           component: CongratulationsComponent,   
  //           cssClass: 'qr-success-modal'                     
  //         });  
  //         await modal.present();
  //       })
  //     ),
  //   { dispatch: false }
  // );

  // upgradeAccountFailed$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(fromAccountActions.upgradeAccountFailed),
  //       tap(async (action) => {   
  //         this.ngZone.run(() => {
  //           this.navCtrl.back();
  //         });
  //         const modal = await this.modalController.create({
  //           component: ErrorComponent,   
  //           cssClass: 'login-error-modal',
  //           componentProps: { error: action.data }   
  //         });
  //         await modal.present();
              
  //       })
  //     ),
  //   { dispatch: false }
  // );

  // sendOtp$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(fromAccountActions.sendOtp),
  //     exhaustMap((action) =>
  //       this.authService.send_otp(action.data).pipe(
  //         map((response: any) => {
  //           this.store.dispatch(setLoadingSpinner({ status: false }));
  //           return fromAccountActions.sendOtpSuccess({ data: response, origin: action?.data?.origin });
  //         }),
  //         catchError((response) => {
  //           console.error('sendOtp$', response);
  //           this.store.dispatch(setLoadingSpinner({ status: false }));
  //           const error = {
  //             status: response.status,
  //             error: this.errorService.getErrorMessage(response)
  //           };
  //           return of(fromAccountActions.sendOtpFailed({ data: error }));
  //         })
  //       )
  //     )
  //   )
  // );

  // sendOtpSuccess$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(fromAccountActions.sendOtpSuccess),
  //       tap(async (action) => {
  //         console.log('sendOtpSuccess$', action.data);
  //         if (action?.origin && action?.origin != 'none' ) {
  //           this.navCtrl.navigateForward(`/home/otp/${action?.origin}`);
  //         }
  //       })
  //     ),
  //   { dispatch: false }
  // );

  // sendOtpFailed$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(fromAccountActions.sendOtpFailed),
  //       tap(async (action) => {
  //         console.log('sendOtpFailed$', action.data.error);          
  //         const modal = await this.modalController.create({
  //           component: ErrorComponent,   
  //           cssClass: 'login-error-modal',
  //           componentProps: { error: action.data } 
  //         });
  //         await modal.present();
              
  //       })
  //     ),
  //   { dispatch: false }
  // );

  // verifyOtp$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(fromAccountActions.verifyOtp),
  //     exhaustMap((action) =>
  //       this.authService.verify_otp(action.data).pipe(
  //         map((response: any) => {
  //           this.store.dispatch(setLoadingSpinner({ status: false }));
  //           // this.alertService.showInfoMessage(response?.message);
  //           this.store.dispatch(getUserProfile());
  //           return fromAccountActions.verifyOtpSuccess({ data: response, origin: action?.data?.origin });
  //         }),
  //         catchError((response) => {
  //           console.error('verifyOtp$', response);
  //           const error = {
  //             status: response.status,
  //             error: this.errorService.getErrorMessage(response)
  //           };
  //           this.alertService.showErrorMessage(error?.error);
  //           return of(setLoadingSpinner({ status: false }));
  //         })
  //       )
  //     )
  //   )
  // );

  // verifyOtpSuccess$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(fromAccountActions.verifyOtpSuccess),
  //       withLatestFrom(this.store.select(getKYCInfo), (action, kycInfo) => {
  //         return { ...action, kycInfo };
  //       }),
  //       tap(async (action) => {
  //         console.log('verifyOtpSuccess$', action);
  //         if (action?.origin === 'kyc') {
  //           const kycInfo = action?.kycInfo;

  //           const id_front = this.sharedService.dataURLtoFile(kycInfo?.id_front, 'frontId');
  //           const id_back = this.sharedService.dataURLtoFile(kycInfo?.id_back, 'backId');
  //           const selfie = this.sharedService.dataURLtoFile(kycInfo?.selfie, 'selfie');

  //           // const kycPayload = {
  //           //   id_type: kycInfo?.id_type,
  //           //   id_number: kycInfo?.id_number,
  //           //   date_of_birth: kycInfo?.date_of_birth,
  //           //   gender: kycInfo?.gender,
  //           //   nationality: kycInfo?.nationality,
  //           //   house_number: kycInfo?.house_number,
  //           //   street: kycInfo?.street,
  //           //   city: kycInfo?.city,
  //           //   region: kycInfo?.region,
  //           //   barangay: kycInfo?.barangay,
  //           //   postal_code: kycInfo?.postal_code,
  //           //   id_front,
  //           //   id_back,
  //           //   selfie,
  //           //   email: kycInfo?.email,
  //           //   mobile_number: kycInfo?.mobile_number,
  //           // };
  //           // console.log({kycPayload});

  //           const formData = new FormData();
  //           formData.append("id_type", kycInfo?.id_type);
  //           formData.append("id_number", kycInfo?.id_number);
  //           formData.append("date_of_birth", kycInfo?.date_of_birth);
  //           formData.append("gender", kycInfo?.gender);
  //           formData.append("nationality", kycInfo?.nationality);
  //           formData.append("house_number", kycInfo?.house_number);
  //           formData.append("street", kycInfo?.street);
  //           formData.append("city", kycInfo?.city);
  //           formData.append("region", kycInfo?.region);
  //           formData.append("barangay", kycInfo?.barangay);
  //           formData.append("postal_code", kycInfo?.postal_code);
  //           formData.append("id_front", id_front);
  //           formData.append("id_back", id_back);
  //           formData.append("selfie", selfie);
  //           if (kycInfo?.email && kycInfo?.email != undefined) {
  //             formData.append("email", kycInfo?.email);  
  //           }
  //           if (kycInfo?.mobile_number && kycInfo?.mobile_number != undefined) {
  //             formData.append("mobile_number", kycInfo?.mobile_number);  
  //           }
  //           console.log({formData});

  //           this.store.dispatch(setLoadingSpinner({ status: true }));
  //           this.store.dispatch(fromAccountActions.upgradeAccount({ data: formData }));
  //         }
  //       })
  //     ),
  //   { dispatch: false }
  // );

  // getTransactions$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(fromAccountActions.getTransactions),
  //     mergeMap((action) => {
  //       return this.accountService.transactions(action?.page).pipe(
  //         map((response: APIResponse) => {           
  //           console.log('getTransactions$', response);
  //           return fromAccountActions.getTransactionsSuccess({ data: response?.details});
  //         })
  //       );
  //     })
  //   )
  // );

  // transactions$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(ROUTER_NAVIGATION),
  //     filter((r : RouterNavigatedAction) => {
  //       return r.payload.routerState.url.startsWith('/home/transaction-history') || r.payload.routerState.url.startsWith('/home/bottom-tabs/dashboard')
  //     }),
  //     mergeMap(() => {
  //       return this.accountService.transactions(1).pipe(
  //         map((response: APIResponse) => {
  //           console.log('transactions$', response);
  //           return fromAccountActions.getTransactionsSuccess({ data: response?.details});
  //         })
  //       );
  //     })
  //   );
  // });

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private errorService: ErrorService,
    private alertService: AlertService,
    private accountService: AccountService,
    private modalController: ModalController,
    private navCtrl: NavController,
    private authService : AuthService,
    private sharedService: SharedService,
    private ngZone: NgZone
  ) {}
}
