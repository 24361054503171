import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BYPASS_HEAD } from '../interceptor/token.interceptor';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(
    private http: HttpClient
  ) { }

  fetchRegions(): Observable<any>{    
    return this.http.get(environment.locationUrl +'/regions/', { context: new HttpContext().set(BYPASS_HEAD, true) });        
  }

  fetchCities(code:any): Observable<any>{    
    return this.http.get(environment.locationUrl +'/regions/'+ code +'/cities/', { context: new HttpContext().set(BYPASS_HEAD, true) });        
  }
}
