import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss'],
})
export class SuccessComponent implements OnInit {

  constructor(
    private modalController: ModalController,
    public navParams: NavParams,
  ) { }

  ngOnInit() {
    console.log(this.navParams)
  }

  dismiss(){
    this.modalController.dismiss();
  }
}
