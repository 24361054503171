import { createReducer, on } from '@ngrx/store';
import * as fromAuthActions from '../actions/auth.actions';
import * as fromWalletActions from '../actions/wallet.actions';
import { Wallet } from 'src/app/models/wallet.model';
import { Dashboard } from 'src/app/models/dashboard.model';

export const featureKey = 'wallet';

export interface State {
  wallets: Array<Wallet>,
  balance: Array<Dashboard>
}


export const initialState: State = {
  wallets: [],
  balance: []
};

export const reducer = createReducer(
  initialState,
  on(fromWalletActions.getAllWalletSuccess, (state: State, props: any) => ({
    ...state,
    wallets: props.data
  })),
  on(fromWalletActions.dashboardBalanceSuccess, (state: State, props: any) => ({
    ...state,
    balance: props.data
  })),
  on(fromAuthActions.autoLogout, (state: State, props) => initialState)
  );
