import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RatesService {

  constructor(
    private http: HttpClient
  ) { }

  getAllRates(): Observable<any> {
    return this.http
      .get<any>(environment.apiUrl + '/v1/rates');
  }

  getRatesById(id = 0): Observable<any> {
    return this.http
      .get<any>(environment.apiUrl + `/v1/rates/${id}`);
  }

}
