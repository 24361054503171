import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-confirm-convert',
  templateUrl: './confirm-convert.component.html',
  styleUrls: ['./confirm-convert.component.scss'],
})
export class ConfirmConvertComponent implements OnInit {

  transaction: any;

  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() {
    setTimeout(() => {
      console.log('this.transaction', this.transaction);
    }, 3000);
  }

  confirm() {
    // add params to set confirming
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  dismiss(){
    this.modalController.dismiss();
  }

}
