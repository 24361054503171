import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-send-success',
  templateUrl: './send-success.component.html',
  styleUrls: ['./send-success.component.scss'],
})
export class SendSuccessComponent implements OnInit {

  transaction: any;
  receiver: string;
  
  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() {
    if (this.transaction?.send_via === 'mobile_number') {
      this.receiver = this.transaction?.mobile_number;
    } else if (this.transaction?.send_via === 'email') {
      this.receiver = this.transaction?.email;
    } else if (this.transaction?.send_via === 'public_address') {
      this.receiver = this.transaction?.transaction?.receiver;
    }
  }

  dismiss(){
    this.modalController.dismiss();
  }

}
