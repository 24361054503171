import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { timeout, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  private kyc = new BehaviorSubject(null);  
  getKYCData = this.kyc.asObservable();
  private formData = new BehaviorSubject(null);
  // getFormData = this.formData.asObservable();
  constructor(
    private http: HttpClient
  ) { }

  setKYCData(data:any){
    this.kyc.next(data);
  }
  setFormData(data:any){
    this.formData.next(data);
  }

  getFormData():Observable<any>{
    return this.formData.asObservable();
  }

  getGasFee(tokenSymbol: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.http.get<any>(environment.apiUrl + `/v1/gas_fee/${tokenSymbol}`)
      .pipe(timeout(30000))
      .subscribe( response => {
        resolve(response);
      },
      error => {
        reject(error);
      });
    });
  }

  dataURLtoFile(dataurl, filename) { 
    if(dataurl){
      var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), 
      n = bstr.length, 
      u8arr = new Uint8Array(n);
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
    }
    return new File([u8arr], filename, {type:mime});
  }
}
