/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */
import { createAction, props } from '@ngrx/store';

export enum ActionTypes {
  GET_REGION = '[Location] Get region',
  GET_REGION_SUCCESS = '[Location] Get region success',  
}

export const getRegion = createAction(
  ActionTypes.GET_REGION
);

export const getRegionSuccess = createAction(
  ActionTypes.GET_REGION_SUCCESS,
  props<{ data: any }>()
);