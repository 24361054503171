import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {

  error: any

  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() {

  }
 

  dismiss(){
    this.modalController.dismiss();
  }
}
