import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, exhaustMap, catchError, tap, withLatestFrom, mergeMap, filter, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as fromRatesActions from '../actions/rates.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { ErrorService } from '../../services/error.service';
import { setLoadingSpinner } from '../actions/shared.actions';
import { AlertService } from '../../services/alert.service';
import { RouterNavigatedAction, ROUTER_NAVIGATION } from '@ngrx/router-store';
import { APIResponse } from 'src/app/models/api-response.model';
import { Location } from '@angular/common';
import { ModalController } from '@ionic/angular';
import { DashboardService } from 'src/app/services/dashboard.service';
import { RatesService } from 'src/app/services/rates.service';

@Injectable()
export class RatesEffects {

  getAllRates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRatesActions.getAllRates),
      mergeMap(() => {
        return this.ratesService.getAllRates().pipe(
          map((response: APIResponse) => {
            console.log('getAllRates$', response);
            return fromRatesActions.getAllRatesSuccess({ data: response.details});
          })
        );
      })
    )
  );

  allRates$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      filter((r : RouterNavigatedAction) => {
        return r.payload.routerState.url.startsWith('/home/convert')
      }),
      mergeMap(() => {
        return this.ratesService.getAllRates().pipe(
          map((response: APIResponse) => {
            console.log('allRates$', response);
            return fromRatesActions.getAllRatesSuccess({ data: response.details});
          })
        );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private errorService: ErrorService,
    private alertService: AlertService,
    private ratesService: RatesService,
    private dashboardService: DashboardService,
    private location: Location,
    private modalController: ModalController
  ) {}
}
