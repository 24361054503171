import { Injectable, NgZone } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, exhaustMap, catchError, tap, withLatestFrom, mergeMap, filter, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as fromCreditsActions from '../actions/credits.actions';
import * as fromWalletActions from '../actions/wallet.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { ErrorService } from '../../services/error.service';
import { setLoadingSpinner } from '../actions/shared.actions';
import { AlertService } from '../../services/alert.service';
import { RouterNavigatedAction, ROUTER_NAVIGATION } from '@ngrx/router-store';
import { APIResponse } from 'src/app/models/api-response.model';
import { Location } from '@angular/common';
import { ModalController, NavController } from '@ionic/angular';
import { CreditsService } from 'src/app/services/credits.service';
import { MoveSuccessComponent } from 'src/app/shared/modals/move-success/move-success.component';
import { SendSuccessComponent } from 'src/app/shared/modals/send-success/send-success.component';
import { ConfirmSendComponent } from 'src/app/shared/modals/confirm-send/confirm-send.component';
import { ConfirmConvertComponent } from 'src/app/shared/modals/confirm-convert/confirm-convert.component';
import { ConvertSuccessCompomnent } from 'src/app/shared/modals/convert-success/convert-success.component';
import { ErrorComponent } from 'src/app/shared/modals/error/error.component';

@Injectable()
export class CreditsEffects {

  sendCreditsConfirmaton$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromCreditsActions.sendCreditsConfirmaton),
        tap(async (action) => {
          console.log('sendCreditsConfirmaton$', action.data);
          const modal = await this.modalController.create({
            component: ConfirmSendComponent,
            cssClass: 'confirm-send-modal',
            componentProps: {
              transaction: action.data
            }
          });
          modal.onDidDismiss().then(
            (res) => {
              if (res.data != undefined) {
                this.store.dispatch(setLoadingSpinner({status: true}));
                this.store.dispatch(fromCreditsActions.sendCredits({data: action.data}))
              }
            }
          );
          return await modal.present();
        })
      ),
    { dispatch: false }
  );

  sendCredits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCreditsActions.sendCredits),
      switchMap((action) =>
        this.creditsService.sendCredits(action.data).pipe(
          map((response) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            this.store.dispatch(fromWalletActions.getAllWallet());
            return fromCreditsActions.sendCreditsSuccess({ data: action.data });
          }),
          catchError((response) => {
            console.error('sendCredits$', response);
            this.store.dispatch(setLoadingSpinner({ status: false }));
            const error = {
              status: response.status,
              error: this.errorService.getErrorMessage(response)
            };
            return of(fromCreditsActions.sendCreditsFailed({ data: error }));
          })
        )
      )
    )
  );

  sendCreditsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromCreditsActions.sendCreditsSuccess),
        tap(async (action) => {
          console.log('sendCreditsSuccess$', action.data);
          this.ngZone.run(() => {
            this.navCtrl.back();
          });
          const modal = await this.modalController.create({
            component: SendSuccessComponent,
            cssClass: 'send-success-modal',
            componentProps: {
              transaction: action.data
            }
          });
          return await modal.present();
        })
      ),
    { dispatch: false }
  );

  sendCreditsFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromCreditsActions.sendCreditsFailed),
        tap(async (action) => {
          console.log('sendCreditsFailed$', action.data);
          const modal = await this.modalController.create({
            component: ErrorComponent,
            cssClass: 'login-error-modal',
            componentProps: { error: action.data } 
          });
          return await modal.present();
        })
      ),
    { dispatch: false }
  );

  moveCredits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCreditsActions.moveCredits),
      switchMap((action) =>
        this.creditsService.moveCredits(action.data).pipe(
          map((response) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            this.store.dispatch(fromWalletActions.getAllWallet());
            return fromCreditsActions.moveCreditsSuccess({ data: action.data });
          }),
          catchError((response) => {
            console.error('moveCredits$', response);
            const error = {
              status: response.status,
              error: this.errorService.getErrorMessage(response)
            };
            this.alertService.showErrorMessage(error?.error);
            return of(setLoadingSpinner({ status: false }));
          })
        )
      )
    )
  );

  moveCreditsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromCreditsActions.moveCreditsSuccess),
        tap(async (action) => {
          console.log('moveCreditsSuccess$', action.data);
          this.ngZone.run(() => {
            this.navCtrl.back();
          });
          const modal = await this.modalController.create({
            component: MoveSuccessComponent,
            cssClass: 'move-success-modal',
            componentProps: {
              transaction: action.data
            }
          });
          return await modal.present();
        })
      ),
    { dispatch: false }
  );

  convertCreditsConfirmaton$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromCreditsActions.convertCreditsConfirmaton),
        tap(async (action) => {
          console.log('convertCreditsConfirmaton$', action.data);
          const modal = await this.modalController.create({
            component: ConfirmConvertComponent,
            cssClass: 'confirm-convert-modal',
            componentProps: {
              transaction: action.data
            }
          });
          modal.onDidDismiss().then(
            (res) => {
              if (res.data != undefined) {
                this.store.dispatch(setLoadingSpinner({status: true}));
                this.store.dispatch(fromCreditsActions.convertCredits({data: action.data}))
              }
            }
          );
          return await modal.present();
        })
      ),
    { dispatch: false }
  );

  convertCredits$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCreditsActions.convertCredits),
      switchMap((action) =>
        this.creditsService.convertCredits(action.data).pipe(
          map((response) => {
            this.store.dispatch(setLoadingSpinner({ status: false }));
            this.store.dispatch(fromWalletActions.getAllWallet());
            return fromCreditsActions.convertCreditsSuccess({ data: action.data });
          }),
          catchError((response) => {
            console.error('convertCredits$', response);
            const error = {
              status: response.status,
              error: this.errorService.getErrorMessage(response)
            };
            this.alertService.showErrorMessage(error?.error);
            return of(setLoadingSpinner({ status: false }));
          })
        )
      )
    )
  );

  convertCreditsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromCreditsActions.convertCreditsSuccess),
        tap(async (action) => {
          console.log('convertCreditsSuccess$', action.data);
          this.ngZone.run(() => {
            this.navCtrl.back();
          });
          const modal = await this.modalController.create({
            component: ConvertSuccessCompomnent,
            cssClass: 'convert-success-modal',
            componentProps: {
              transaction: action.data
            }
          });
          return await modal.present();
        })
      ),
    { dispatch: false }
  );

  getCreditsLimit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCreditsActions.getCreditsLimit),
      mergeMap(() =>
        this.creditsService.creditsLimit().pipe(
          map((response: APIResponse) => {
            return fromCreditsActions.getCreditsLimitSuccess({ data: response?.details });
          })
        )
      )
    )
  );

  creditsLimit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      filter((r : RouterNavigatedAction) => {
        return r.payload.routerState.url.startsWith('/home/credit-limits')
      }),
      mergeMap(() => {
        return this.creditsService.creditsLimit().pipe(
          map((response: APIResponse) => {
            console.log('creditsLimit$', response);
            return fromCreditsActions.getCreditsLimitSuccess({ data: response?.details });
          })
        );
      })
    );
  });

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private errorService: ErrorService,
    private alertService: AlertService,
    private creditsService: CreditsService,
    private navCtrl: NavController,
    private modalController: ModalController,
    private ngZone: NgZone
  ) {}
}
