import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Wallet } from 'src/app/models/wallet.model';

@Component({
  selector: 'app-move-success',
  templateUrl: './move-success.component.html',
  styleUrls: ['./move-success.component.scss'],
})
export class MoveSuccessComponent implements OnInit {

  transaction: any;

  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() {
    console.log('transaction', this.transaction)
  }

  dismiss(){
    this.modalController.dismiss();
  }

}
