import { createReducer, on } from '@ngrx/store';
import * as fromAuthActions from '../actions/auth.actions';
import * as fromAccountActions from '../actions/account.actions';
import { UserProfile } from 'src/app/models/user-profile.model';
import { Transaction } from 'src/app/models/transaction.model';

export const featureKey = 'account';

export interface State {
  user: UserProfile;
  transactions: Array<Transaction>;
  kycInfo: any;
  attendanceList: any;
}

export const initialState: State = {
  user: null,
  transactions: [],
  kycInfo: null,
  attendanceList: []
};

export const reducer = createReducer(
  initialState,  
  on(fromAccountActions.updateUserProfileSuccess, (state: State, props: any) => ({
    ...state,
    user: props.data
  })),
  on(fromAccountActions.setOnlineAttendance, (state: State, props: any) => ({
    ...state,
    attendanceList: props.data
  })),
  // on(fromAccountActions.getTransactionsSuccess, (state: State, props: any) => ({
  //   ...state,
  //   transactions: props.data
  // })),
  // on(fromAccountActions.setKYCInfo, (state: State, props: any) => ({
  //   ...state,
  //   kycInfo: props.data
  // })),
  on(fromAuthActions.autoLogout, (state: State, props) => initialState)
);
