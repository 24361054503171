import { createReducer, on } from '@ngrx/store';
import * as fromSharedActions from '../actions/shared.actions';
import * as fromAuthActions from '../actions/auth.actions';

export const featureKey = 'shared';

export interface State {
  showLoading: boolean;
  errorMessage: string;
}

export const initialState: State = {
  showLoading: false,
  errorMessage: '',
};

export const reducer = createReducer(
  initialState,
  on(fromSharedActions.setLoadingSpinner, (state: State, props: any) => ({
    ...state,
    showLoading: props.status,
  })),
  on(fromSharedActions.setErrorMessage, (state: State, props: any) => ({
    ...state,
    errorMessage: props.message,
  })),
  on(fromAuthActions.autoLogout, (state: State, props) => initialState)
);
