import { MetaReducer } from '@ngrx/store';
import * as fromAuth from './auth.reducer';
import { routerReducer } from '@ngrx/router-store';
import { AppState } from '../app.state';
import { environment } from 'src/environments/environment';
import * as fromShared from './shared.reducer';
import * as fromAccount from './account.reducer';
import * as fromWallet from './wallet.reducer';
import * as fromCredits from './credits.reducer';
import * as fromLocation from './location.reducer';
import * as fromRates from './rates.reducer';

export const reducers = {
  auth: fromAuth.reducer,
  shared: fromShared.reducer,
  router: routerReducer,
  account: fromAccount.reducer,
  wallet: fromWallet.reducer,
  credits: fromCredits.reducer,
  location: fromLocation.reducer,
  rates: fromRates.reducer
};

export const metaReducers: MetaReducer<AppState>[] = !environment.production
  ? []
  : [];
