import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';

import { AlertController, IonRouterOutlet, isPlatform, MenuController, NavController, Platform, ToastController } from '@ionic/angular';

import { OfflineStorageService } from './services/offline-storage.service';
import { INTRO_KEY } from 'src/app/guards/intro.guard';
import { filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from './store/app.state';
import { getLoading } from './store/selectors/shared.selector';
import * as fromAuthActions from './store/actions/auth.actions'
import { App } from '@capacitor/app';
import { StatusBar, Style } from '@capacitor/status-bar';
import { getCurrentRoute } from './store/selectors/router.selector';
import { AutoCloseOverlaysService } from './services/autocloseoverlays.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  appPages = [
    {
      title: 'Dashboard',
      url: '/home/bottom-tabs/dashboard',
      icon: 'home'
    },
  ];

  loggedIn: any;

  dark = false;
  swipeGesture = false;
  disabledMenu = true;

  showLoading$: Observable<boolean>;
  currentRoute$: Observable<any>;

  homeTab = 0;
  tokenTab = -1;
  merchantTab = -1;
  moreTab = -1;

  homePages = ["dashboard"];
  tokenPages = ["crypto-profile"];
  morePages = ["profile", "wallet", "crypto-profile-more"];

  @ViewChild(IonRouterOutlet, { static : true }) ionRouterOutlet: IonRouterOutlet;

  alertPresented: any;

  constructor(
    private menu: MenuController,
    private swUpdate: SwUpdate,
    private toastCtrl: ToastController,
    private offlineStorageService: OfflineStorageService,
    private store: Store<AppState>,
    private platform: Platform,
    private navCtrl: NavController,
    private ngZone: NgZone,
    private autoCloseOverlaysService: AutoCloseOverlaysService,
    private alertController: AlertController
  ) {
    this.initializeApp();
  }

  async ngOnInit() {
    console.log('AppComponent ngOnInit');

    const updatesAvailable = this.swUpdate.versionUpdates.pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
      map(evt => ({
        type: 'UPDATE_AVAILABLE',
        current: evt.currentVersion,
        available: evt.latestVersion,
      })
    ));
    updatesAvailable.subscribe(async res => {
      const toast = await this.toastCtrl.create({
        message: 'Update available!',
        position: 'bottom',
        buttons: [
          {
            role: 'cancel',
            text: 'Reload'
          }
        ]
      });

      await toast.present();

      toast.onDidDismiss()
      .then(() => this.swUpdate.activateUpdate())
      .then(() => window.location.reload());
    });
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      this.showLoading$ = this.store.select(getLoading);
      this.currentRoute$ = this.store.select(getCurrentRoute);

      if (isPlatform("capacitor")) {
        // Statusbar
        await StatusBar.setStyle({ style: Style.Dark });
        this.currentRoute$.subscribe(async (route) => {
          if (route) {
            const currentPage = route.url.substring(route.url.lastIndexOf('/') + 1);
            if (currentPage === 'login') {
              await StatusBar.setBackgroundColor({ color: "#3164DA"});
            } else if (currentPage === 'dashboard' || currentPage === 'profile') {
              await StatusBar.setBackgroundColor({ color: "#367DB8"});
            } else {
              await StatusBar.setBackgroundColor({ color: "#3973F3"});
            }
          }
        });
      }
      
      this.store.dispatch(fromAuthActions.autoLogin());

      // Back navigation 
      this.currentRoute$.subscribe((route) => {
        if (route && route.url ) {
          const currentPage = route.url.substring(route.url.lastIndexOf('/') + 1);
          this.homeTab = this.homePages.indexOf(currentPage);
          this.tokenTab = this.tokenPages.indexOf(currentPage);
          this.moreTab = this.morePages.indexOf(currentPage);
        }
      }); 
      window.addEventListener('navigation:back', async (e: any) => {
        console.log('navigation:back', e?.detail);
        const isNavigateBack = e?.detail;
        if (isNavigateBack) {
          const hasClosedOverlays = await this.autoCloseOverlaysService.trigger();
          console.log({hasClosedOverlays});
          if (hasClosedOverlays) {
            // do not back navigation
          }
          else if (this.ionRouterOutlet.canGoBack()) {
            this.ngZone.run(() => {
              this.navCtrl.back();
            });
          } else {
            if (this.homeTab > 0 || this.tokenTab > 0 || this.moreTab > 0) {
              this.ngZone.run(() => {
                this.navCtrl.back();
              });
            }
          }
        }
      });
      App.addListener('backButton', data => {
        if (data?.canGoBack) {
          window.dispatchEvent(new CustomEvent('navigation:back', { detail: true}));  
        }
      });

      // Logout
      window.addEventListener('auth:logout', (e: any) => {
        console.log('auth:logout', e?.detail);
        const isLogout = e?.detail;
        if (isLogout) {
          this.logout();
        }
      });

      // Session Timeout
      window.addEventListener('auth:timeout', (e: any) => {
        console.log('auth:timeout', e?.detail);
        const isTimeout = e?.detail;
        if (isTimeout) {
          this.logout();
          this.sessionTimeOut();
        }
      });

    });
  }

  async logout() {
    // await this.offlineStorageService.removeItem('isShowedUpgrade');
    this.store.dispatch(fromAuthActions.autoLogout());
  }

  async openIntro() {
    this.menu.enable(false);
    this.offlineStorageService.setObject(INTRO_KEY, false);
    this.navCtrl.navigateRoot('intro');
  }

  async sessionTimeOut() {
    if (!this.alertPresented) {
      this.alertPresented = true;
      const alert = await this.alertController.create({
        message: "Session Timeout!",
        backdropDismiss: false,
        buttons: [
          {
            text: "OK",
            handler: async () => {
              
            },
          },
        ],
      });
      await alert.present();
    }
  }
}
