import { createReducer, on } from '@ngrx/store';
import * as fromAuthActions from '../actions/auth.actions';
import * as fromRatesActions from '../actions/rates.actions';
import { Rate } from 'src/app/models/rate.model';

export const featureKey = 'rates';

export interface State {
  rates: Array<Rate>;
}

export const initialState: State = {
  rates: [],
};

export const reducer = createReducer(
  initialState,
  on(fromRatesActions.getAllRatesSuccess, (state: State, props: any) => ({
    ...state,
    rates: props.data,
  })),
  on(fromAuthActions.autoLogout, (state: State, props) => initialState)
);
