/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */
import { createAction, props } from '@ngrx/store';

export enum ActionTypes {
  GET_USER_PROFILE = '[Account] Update User Profile',
  GET_USER_PROFILE_SUCCESS = '[Account] User Profile Success',
  UPDATE_USER_PROFILE = '[Account] Update User Profile',
  UPDATE_USER_PROFILE_SUCCESS = '[Account] Update User Profile Success',
  USER_TIME_IN_OUT = '[Account] User Time In',
  CHANGE_PASSWORD = '[Auth] Change Password',
  CHANGE_PASSWORD_SUCCESS = '[Auth] Change Password Success',
  CHANGE_PASSWORD_FAILED = '[Auth] Change Password Failed',
  // UPGRADE_ACCOUNT = '[Account] Upgrade account',
  // UPGRADE_ACCOUNT_SUCCESS = '[Account] Upgrade account success',
  // UPGRADE_ACCOUNT_FAILED = '[Account] Upgrade account failed',
  // SEND_OTP = '[Account] Sent OTP',
  // SEND_OTP_SUCCESS = '[Account] Sent OTP Success',
  // SEND_OTP_FAILED = '[Account] Sent OTP Failed',
  // VERIFY_OTP = '[Account] Verify OTP',
  // VERIFY_OTP_SUCCESS = '[Account] Verify OTP success',
  // GET_TRANSACTIONS = '[Account] Get Transaction',
  // GET_TRANSACTIONS_SUCCESS = '[Account] Get Transaction Success',
  // SET_KYC_INFO = '[Account] Set KYC Info',

  SET_ONLINE_ATTENDANCE = '[Account] Set Online Attendance',

  USER_ATTENDANCE_SUCCESS = '[Account] User Attendance Success',
  USER_ATTENDANCE_FAILED = '[Account] User Attendance Failed',
}

export const getUserProfile = createAction(
  ActionTypes.GET_USER_PROFILE
);

export const getUserProfileSuccess = createAction(
  ActionTypes.GET_USER_PROFILE_SUCCESS,
  props<{ data: any }>()
);

export const updateUserProfile = createAction(
  ActionTypes.UPDATE_USER_PROFILE,
  props<{ data: any }>()
);

export const updateUserProfileSuccess = createAction(
  ActionTypes.UPDATE_USER_PROFILE_SUCCESS,
  props<{ data: any }>()
);

export const setOnlineAttendance = createAction(
  ActionTypes.SET_ONLINE_ATTENDANCE,
  props<{ data: any }>()
);

export const timeInOut = createAction(
  ActionTypes.USER_TIME_IN_OUT,
  props<{ data: any }>()
);

export const attendanceSuccess = createAction(
  ActionTypes.USER_ATTENDANCE_SUCCESS,
  props<{ data: any }>()
);

export const attendanceFailed = createAction(
  ActionTypes.USER_ATTENDANCE_FAILED,
  props<{ data: any, transRequest: string, error: any }>()
);

export const changePassword = createAction(
  ActionTypes.CHANGE_PASSWORD,
  props<{ data: any; }>()
);

export const changePasswordSuccess = createAction(
  ActionTypes.CHANGE_PASSWORD_SUCCESS,
  props<{ data: any }>()
);

export const changePasswordFailed = createAction(
  ActionTypes.CHANGE_PASSWORD_FAILED,
  props<{ data: any }>()
);

// export const upgradeAccount = createAction(
//   ActionTypes.UPGRADE_ACCOUNT,
//   props<{ data: any }>()
// );

// export const upgradeAccountSuccess = createAction(
//   ActionTypes.UPGRADE_ACCOUNT_SUCCESS,
//   props<{ tempUserSession: any }>()
// );

// export const upgradeAccountFailed = createAction(
//   ActionTypes.UPGRADE_ACCOUNT_FAILED,
//   props<{ data: any }>()
// );

// export const sendOtp = createAction(
//   ActionTypes.SEND_OTP,
//   props<{ data: any }>()
// );

// export const sendOtpSuccess = createAction(
//   ActionTypes.SEND_OTP_SUCCESS,
//   props<{ data: any, origin: string }>()
// );

// export const sendOtpFailed = createAction(
//   ActionTypes.SEND_OTP_FAILED,
//   props<{ data: any }>()
// );

// export const verifyOtp = createAction(
//   ActionTypes.VERIFY_OTP,
//   props<{ data: any }>()
// );

// export const verifyOtpSuccess = createAction(
//   ActionTypes.VERIFY_OTP_SUCCESS,
//   props<{ data: any, origin: string }>()
// );

// export const getTransactions = createAction(
//   ActionTypes.GET_TRANSACTIONS,
//   props<{page: Number}>()
// );

// export const getTransactionsSuccess = createAction(
//   ActionTypes.GET_TRANSACTIONS_SUCCESS,
//   props<{ data: any }>()
// );

// export const setKYCInfo = createAction(
//   ActionTypes.SET_KYC_INFO,
//   props<{ data: any }>()
// );
