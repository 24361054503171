import { createReducer, on } from '@ngrx/store';
import * as fromAuthActions from '../actions/auth.actions';
import * as fromCreditsActions from '../actions/credits.actions';
import { Wallet } from 'src/app/models/wallet.model';
import { CreditsLimit } from 'src/app/models/credits-limit.model';

export const featureKey = 'credits';

export interface State {
  fromWallet: Wallet;
  amount: number;
  toWallet: Wallet;
  other: any;
  creditsLimit: CreditsLimit;
}

export const initialState: State = {
  fromWallet: null,
  amount: null,
  toWallet: null,
  other: null,
  creditsLimit: null
};

export const reducer = createReducer(
  initialState,
  on(fromCreditsActions.getCreditsLimitSuccess, (state: State, props: any) => ({
    ...state,
    creditsLimit: props.data
  })),
  on(fromAuthActions.autoLogout, (state: State, props) => initialState)
  );
