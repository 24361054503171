/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */
import { createAction, props } from '@ngrx/store';

export enum ActionTypes {
  SET_LOADING_SPINNER = '[Shared] set loading spinner',
  SET_ERROR_MESSAGE = '[Shared] set error message',
}

export const setLoadingSpinner = createAction(
  ActionTypes.SET_LOADING_SPINNER,
  props<{ status: Boolean }>()
);

export const setErrorMessage = createAction(
  ActionTypes.SET_ERROR_MESSAGE,
  props<{ message: string }>()
);
