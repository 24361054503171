import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserProfile } from '../models/user-profile.model';
import { BEARER_ONLY } from '../interceptor/token.interceptor';
@Injectable({
  providedIn: 'root',
})
export class AccountService {

  constructor(
    private http: HttpClient
  ) { }

  userProfile(): Observable<any> {
    return this.http
      .get<UserProfile>(environment.apiUrl + '/api/auth/profile');
  }

  changePassword(item): Observable<any> {
    return this.http
      .post<any>(environment.apiUrl + '/api/auth/change-password', JSON.stringify(item));
  }

  upgradeAccount(item): Observable<any> {
    return this.http
      .post(environment.apiUrl + '/v1/kyc', item, { context: new HttpContext().set(BEARER_ONLY, true) });
  }

  transactions(page): Observable<any> {
    return this.http
      .get<UserProfile>(environment.apiUrl + `/v1/transactions?page=${page}`);
  }

  attendances(): Observable<any> {
    return this.http
      .get<any>(environment.apiUrl + '/api/user/attendance/list');
  }

  timeInOut(item): Observable<any> {
    return this.http
      .post<any>(environment.apiUrl + '/api/user/attendance', JSON.stringify(item));
  }

}
