/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */
import { createAction, props } from '@ngrx/store';

export enum ActionTypes {
  GET_ALL_RATES = '[Account] Get all rates',
  GET_ALL_RATES_SUCCESS = '[Account] Get all rates success',
}

export const getAllRates = createAction(
  ActionTypes.GET_ALL_RATES
);

export const getAllRatesSuccess = createAction(
  ActionTypes.GET_ALL_RATES_SUCCESS,
  props<{ data: any }>()
);