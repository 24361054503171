import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, exhaustMap, catchError, tap, withLatestFrom, mergeMap, filter, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as fromLocationActions from '../actions/location.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../app.state';
import { ErrorService } from '../../services/error.service';
import { setLoadingSpinner } from '../actions/shared.actions';
import { AlertService } from '../../services/alert.service';
import { RouterNavigatedAction, ROUTER_NAVIGATION } from '@ngrx/router-store';
import { APIResponse } from 'src/app/models/api-response.model';
import { Location } from '@angular/common';
import { WalletService } from 'src/app/services/wallet.service';
import { ModalController } from '@ionic/angular';
import { DashboardService } from 'src/app/services/dashboard.service';
import { LocationService } from 'src/app/services/location.service';

@Injectable()
export class LocationEffect {

  getLocation$ = createEffect(() =>  
    this.actions$.pipe(
      ofType(fromLocationActions.getRegion),
      mergeMap(() => {
        console.log('test');
        return this.locationService.fetchRegions()
        .pipe(
          map((response: any) => {
            console.log('getAllWallet$', response);
            return fromLocationActions.getRegionSuccess(response);
          }),
          catchError(() => {
            console.log('testaaa')
            return of(fromLocationActions.getRegionSuccess({ data: null }));
          })
        );
      })
    )
  );

  // allWallet$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(ROUTER_NAVIGATION),
  //     filter((r : RouterNavigatedAction) => {
  //       return r.payload.routerState.url.startsWith('/home/bottom-tabs/wallet') ||
  //       r.payload.routerState.url.startsWith('/home/bottom-tabs/crypto-profile') ||
  //       r.payload.routerState.url.startsWith('/home/send-credit') ||
  //       r.payload.routerState.url.startsWith('/home/move-credit')
  //     }),
  //     mergeMap(() => {
  //       return this.locationService.fetchRegions().pipe(
  //         map((response: APIResponse) => {
  //           console.log('allWallet$', response);
  //           return fromLocationActions.getRegionSuccess({ data: response.details});
  //         })
  //       );
  //     })
  //   );
  // });
  

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private errorService: ErrorService,
    private alertService: AlertService,
    private locationService: LocationService,
    private dashboardService: DashboardService,
    private location: Location,
    private modalController: ModalController
  ) {}
}
